<template>
    <div>
        <!-- 近一年洗涤量趋势 -->
        <Echart :options="options" id="bottomLeftChart" height="370px" width="100%"></Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart'
import * as api from '@/api/index'

export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        cdata: {
            handler(newData) {
                let max = 0
                max = Math.max(...newData.seriesData.map(item => item.value))
                console.log(newData, max, 'seriesData------')

                let _this = this
                _this.options = {
                    tooltip: {
                        trigger: "axis",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        axisPointer: {
                            type: "shadow",
                            label: {
                                show: true,
                                backgroundColor: "#7B7DDC"
                            }
                        },
                        textStyle: {
                            fontSize: 12,
                        },
                        splitLine: {
                            lineStyle: {
                                color: [
                                "rgba(238, 197, 102, 0.1)",
                                "rgba(238, 197, 102, 0.2)",
                                "rgba(238, 197, 102, 0.4)",
                                "rgba(238, 197, 102, 0.6)",
                                "rgba(238, 197, 102, 0.8)",
                                "rgba(238, 197, 102, 1)"
                                ].reverse()
                            }
                        },
                        transitionDuration: 0,
                        formatter: function (params) {
                            // console.log(params, 'tooltip--------')
                            var result = `<span>月份 : ${params[0].name}</span>`
                            if (params[0].data.type.length > 0) {
                                params[0].data.type.forEach((item, index) => {

                                    result += `<div>
                                                <div style="margin-top: 10px"><span class="color${index}"> ${params[0].marker} </span>  ${item.categoryName} : ${item.num}</div>
                                            </div>`

                                })
                                return result;
                            } else {
                                result += `<div>
                                                <div style="margin-top: 10px">暂无分类数据</div>
                                            </div>`
                                return result;
                            }

                            // return result;

                        }
                    },
                    grid: {
                        top: "12%",
                        left: "15%",
                        right: "10%",
                        bottom: "53%"
                    },
                    xAxis: {
                        name: '月份',
                        nameTextStyle: {
                            fontSize: 12
                        },
                        data: newData.xAxis,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(161,164,165,0.5)"
                            }
                        },
                        axisLabel: {
                            rotate: 35, // 文字旋转 45 度
                            textStyle: {
                                fontSize: 12
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        max: max + 10,
                        name: '单位/万次',
                        nameTextStyle: {
                            fontSize: 12
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(161,164,165,0.5)"
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(161,164,165,0.5)"
                            }
                        },

                        axisLabel: {
                            formatter: function (params) {
                                // console.log(params, '-y轴+++++++++++++')
                                return params
                            },
                            textStyle: {
                                fontSize: 12
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Direct',
                            type: 'bar',
                            barWidth: '60%',
                            data: newData.seriesData
                        }
                    ]
                }
                if(this.$screenWidth >= 1520){
                    _this.options.grid = {
                        top: "12%",
                        left: "12%",
                        right: "10%",
                        bottom: "40%"
                    }
                    _this.options.tooltip.textStyle.fontSize = 14
                    _this.options.xAxis.nameTextStyle.fontSize = 14
                    _this.options.xAxis.axisLabel.textStyle.fontSize = 14
                    _this.options.yAxis.nameTextStyle.fontSize = 14
                    _this.options.yAxis.axisLabel.textStyle.fontSize = 14
                }else if( this.$screenWidth >=1400 && this.$screenWidth < 1520){
                    _this.options.grid = {
                        top: "12%",
                        left: "12%",
                        right: "10%",
                        bottom: "49%"
                    }
                }

            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        // 获取当前日期函数

    }
}
</script>
<style lang="scss" >
    .color0 span{
        background-color: #37a2da!important;
    }
    .color1 span{
        background-color: #6871d0!important;
    }
    .color2 span{
        background-color: #4d9f88!important;
    }
    .color3 span{
        background-color: #ffdb5c!important;
    }
    .color4 span{
        background-color: #006de6!important;
    }
    .color5 span{
        background-color: #00d2f5!important;
    }
    .color6 span{
        background-color: #e7bcf3!important;
    }
    .color7 span{
        background-color: #8378ea!important;
    }
</style>