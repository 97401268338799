<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <!-- <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span> -->
        <div class="d-flex">
          <span class="fs-xl text_title mx-2 commonTitle">收送实时监控</span>
        </div>
      </div>
      <div class="body-box">
        <ul class="header_item">
          <li style="width: 5%;">No</li>
          <li style="width: 51%;">医院</li>
          <!-- <li style="width: 15%;">库存</li> -->
          <li style="width: 22%;">今日洗涤量</li>
          <li style="width: 22%;">今日配送量</li>
        </ul>
        <vue-seamless-scroll :data="personList" :class-option="defineScroll" class="seamless-warp"
          ref="vueSeamlessScroll" @mousewheel.native="handleScroll">
          <div class="item">
            <li v-for="(item, index) in personList" :key="index">
              <span style="width: 5%;">{{ index + 1 }}</span>
              <span style="width: 51%;" class="title" v-text="item.customerName"></span>
              <!-- <span style="width: 15%;" class="date" v-text="item.numStock"></span> -->
              <span style="width: 22%;" class="date" v-text="item.numReceive"></span>
              <span style="width: 22%;" class="date" v-text="item.numDelivery"></span>
            </li>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/index'
import { formatTime } from '@/utils/index'
export default {
  data() {
    return {
      config: {
        header: ['组件', '分支', '覆盖率'],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        hoverPause: true,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center']
      },
      params: {
        pageNum: 1,
        pageSize: 5000,
        customerId: '',
        startTime: formatTime(new Date(),'yyyy-MM-dd'),
        endTime: formatTime(new Date(),'yyyy-MM-dd'),
        // startTime: '2024-11-01',
        // endTime: '2024-11-01',
      },
      personList: [],
      timer: null
    }
  },
  watch: {
    personList: {
      handler() {
        // 数据更改时也要加
        this.$nextTick(() => {
          this.$refs.vueSeamlessScroll.reset()
        })
      },
      immediate: true,
    },
  },
  computed: {
    defineScroll() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    //this.params.customerId = this.$route.query.customerId
    // 这里是去重新计算组件内部的高度
    this.$nextTick(() => {
      this.$refs.vueSeamlessScroll.reset()
    })

    this.getData()

    //定时获取接口最新数据
    this.timer = setInterval(() => {
      this.getData()
    }, 10000);
  },
  methods: {
    async getData() {
      const res = await api.getOrderSkuCustomer(this.params)
      // console.log(res, '实时数据-----------')
      if (res.ResultCode == 0) {
        this.personList = res.Result

        // console.log(this.personList, 'this.result---------')
      }
    },
    // 滚动代码
    handleScroll(e) {
      // 改变组件内部 yPos 的值，这样html的translate(0, yPos)就会随之改变
      // e.deltaY是滚动的距离
      this.$refs.vueSeamlessScroll.yPos = this.$refs.vueSeamlessScroll.yPos - e.deltaY
      // 如果是正数 说明是往上滚
      if (this.$refs.vueSeamlessScroll.yPos > 0) {
        this.$refs.vueSeamlessScroll.yPos = 0
        return
      }
      // 如果yPos超过内部实际高度的一半则重新到顶部滚动
      // 一半的原因是因为组件实际上创建了两个dom，以达到无缝衔接的效果
      if (Math.abs(this.$refs.vueSeamlessScroll.yPos) > this.$refs.vueSeamlessScroll.realBoxHeight / 2) {
        this.$refs.vueSeamlessScroll.yPos = 0
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null
    }
  },
}
</script>

<style lang="scss" scoped>
//$box-height: 410px;
//$box-width: 100%;

#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: 100%;
  width: 100%;
  border-radius: 5px;

  .bg-color-black {
    height: 100%;
    border-radius: 10px;
  overflow: hidden;
    
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;
    padding: 5px 10px;

    .header_item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      text-align: center;


      li {
        font-size: 14px;
        padding: 10px;
        //border: 1px solid rgb(32, 73, 108);
        color: #00cdff
      }
    }

    .dv-scr-board {
      // width: 270px;
      //height: 340px;
    }

    .seamless-warp {
      //height: 300px;
      overflow: hidden;
      //background: GREEN;
      .item {
        // padding: 10px;

        li {
          // padding: 10px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          text-align: center;

          span {
            font-size: 14px;
            padding: 5px 10px;
            // border: 1px solid rgb(32, 73, 108);
            color: #00cdff
          }

        }
      }
    }
  }
}
</style>
