import axios from 'axios';
import router from '../router';
// import baseURL from './setBaseUrl'
// import {message} from 'ant-design-vue'
import { Message } from 'element-ui';

const request = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: baseURL,
    // 请求超时时间
    timeout: 60000
});
// post请求头
request.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// request.defaults.headers.common['Authorization'] = localStorage.getItem('Authorization')
// 请求拦截器
request.interceptors.request.use(
    config => {
        // console.log(config, '请求拦截')
        // message.success(config.msg)
        if (localStorage.getItem('Authorization')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('Authorization');
        }
        config.headers['tenant-id'] = '1';
        return config;
    },
    error => {
        console.log(error, '请求拦截错误');
        return Promise.error(error);
    }
);

// 响应拦截器
request.interceptors.response.use(
    response => {
        // console.log(response,'响应拦截')
        // return response.data;
        if (response.status == 200) {
            const res = response.data;
            if (res.code === 0) {
                // console.log(1)
                return response.data;
            } else if (res.code == 4) {
                // console.log(2)
                // message.error(response.data.msg)
            } else if (res.code == 3) {
                if (res.msg == 'ES分页错误') {
                    console.log('es错误')
                } else {
                    // message.error(response.data.msg)
                }

            } else if (res.code == 5) {
                // console.log(2)
                // message.error(response.data.msg)
            } else if (res.code == 401){
                Message.warning('登录失效，请重新登录')
                router.push('/')
            }else {
                if(res?.ResultCode != 0){
                    Message.warning(res.msg)
                }
                //导出excle表
                return response.data;
            }
        }
        // console.log(3)
        return response.data
    },
    error => {
        // console.log(error.response,'响应拦截错误码');
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('Authorization');
                    router.push('/login')
                    // message.error("登录失效,请重新登录！")
                    // alert(1)
                    break
                case 400:
                    // message.error('参数错误')
                    break
                case 403:
                    // message.error('没有当前操作的权限')
                    break
                case 404:
                    // message.error('请求不存在')
                    break
                case 502:
                    // message.error('服务器异常')
                    break;
                case 500:
                    // message.error('服务器挂了')
                    break;
                // 其他错误，直接抛出错误提示                
                default:

            }

        }
        return Promise.reject(error.response);
    }
);

export default request;
