<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="header-box">
          <div class="d-flex jc-center project-title">
            医用织物智能一体化管理中心
          </div>
          <div class="react-right mr-4 react-l-s top-date">
            <span class="react-after"></span>
            <span class="text fs-lg">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
          </div>
          <div class="custom-seamless">
            <div class="text fs-lg">
              让医用织物管理更智能
            </div>
          </div>
          <div class="user-info text-info-1">
            <i class="el-icon-user-solid"></i>
            {{ username }}
          </div>

          <!-- <el-select
            v-model="customerId"
            placeholder="选择医院"
            class="selectCus"
          >
            <el-option
              v-for="item in customerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select> -->

        </div>
        <!-- <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">医用织物数字化管理平台</span>
              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div> -->

        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text fs-xl">国 家 高 新 技 术 企 业</span>
            </div>
            <div class="react-left bg-color-blue ml-3">
              <span class="text"></span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b"></span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text fs-xl">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
          <!-- 左列 -->
          <div class="content-box">
            <div class="border-box-contaier" >
              <dv-border-box-13 :reverse="true" ref="centerLeft1">
                <centerLeft1 />
              </dv-border-box-13>
            </div>
            <!-- 中间 -->
            <div class="border-box-contaier">
              <dv-border-box-13 ref="centerRight2">
                <stock />
              </dv-border-box-13>
            </div>
              <div class="border-box-contaier">
              <dv-border-box-13 ref="centerLeftBottom">
                <report />
              </dv-border-box-13>
            </div>
          </div>
          <!-- 中列 -->
          <div class="center">
            <div class="centerTop">
              <dv-border-box-13 ref="summaryList" v-if="!$route.path.includes('/index2')">
                <summaryList />
              </dv-border-box-13>
              <summaryList :customerList="customerList" v-else />
            </div>
            <div class="centerCenter">
              <dv-border-box-13 ref="centerRight1">
                <centerRight1 v-if="!$route.path.includes('/index2')" />
                <centerTable v-else />
              </dv-border-box-13>
            </div>
            <div class="bottomLeft">
              <dv-border-box-13 ref="bottomLeft">
                <bottomLeft />
              </dv-border-box-13>
            </div>
          </div>
          <!-- 下列 -->
          <div class="bottom-box">
            <div class="border-box-contaier">
              <dv-border-box-13 ref="rightTop">
                <loss />
              </dv-border-box-13>
            </div>
            <div class="border-box-contaier">
              <dv-border-box-13 ref="rightCenter">
                <rightCenter />
              </dv-border-box-13>
            </div>
            <div class="border-box-contaier">
              <dv-border-box-13 ref="rightBottom">
                <lost />
              </dv-border-box-13>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'

import centerLeft1 from './centerLeft1'
import stock from './index2/stock'
import report from './index2/report'

import summaryList from './summaryList'
import centerRight1 from './centerRight1'
import centerTable from './index2/centerTable'
import bottomLeft from './bottomLeft'

import loss from './index2/loss'
import rightCenter from './rightCenter'
import lost from './index2/lost'

import * as Api from '../api/index.js'
export default {
  // mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      username: '',
      customerList:[]
    }
  },
  components: {
    centerLeft1,
    stock,
    report,

    summaryList,
    centerRight1,
    bottomLeft,
    centerTable,

    loss,
    rightCenter,
    lost,
  },
  watch: {
    '$store.state.app.sidebar.opened' () {
      setTimeout(() => {
        this.$refs.centerLeft1.initWH();
        this.$refs.centerRight2.initWH();
        this.$refs.centerLeftBottom.initWH();
        this.$refs.summaryList.initWH();
        this.$refs.centerRight1.initWH();
        this.$refs.bottomLeft.initWH();
        this.$refs.rightTop.initWH();
        this.$refs.rightCenter.initWH();
        this.$refs.rightBottom.initWH();
      }, 300)
    }
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem('accountPsw')))
    this.getCustomerList()
    this.timeFn()
    this.cancelLoading()
    this.username = JSON.parse(localStorage.getItem('accountPsw'))?.v.username
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async getCustomerList(){
      const res = await Api.selectCustomer()
      res.data.unshift({
        id: 0,
        name:'全部医院'
      })
      this.customerList = res.data
      // console.log('fdd',localStorage.getItem('userInfo'))
      if(localStorage.getItem('userInfo')){
        let info = JSON.parse(localStorage.getItem('userInfo'))
        this.customerId = info.customerId
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
#index {
  .bg{
    background-image: url("../assets/images/bg.jpg");
  }
}
</style>
