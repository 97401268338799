<template>
    <div id="centerLeftBottom">
        <div class="bg-color-black">
            <div class="d-flex title-box">
                <!-- <span>
                    <icon name="align-left" class="text-icon"></icon>
                </span> -->
                <span class="fs-xl text_title commonTitle">织物收发数量统计</span>
            </div>
            <div class="d-flex flex-column body-box">
                <ul class="ul_item d-flex flex-column">
                    <li class="d-flex jc-between f14 commonText">
                        <span></span>
                        <span>回收织物</span>
                        <span>发放织物</span>
                    </li>
                    <li class="d-flex jc-between f14 commonText">
                        <span>TD</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[0].numReceive }}</span>件</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[0].numDelivery }}</span>件</span>
                    </li>
                    <li class="d-flex jc-between f14 commonText">
                        <span>MTD</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[1].numReceive }}</span>件</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[1].numDelivery }}</span>件</span>
                    </li>
                    <li class="d-flex jc-between f14 commonText">
                        <span>YTD</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[2].numReceive }}</span>件</span>
                        <span><span class="text-info-1 fs-xl">{{ tabledata[2].numDelivery }}</span>件</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/index'
export default {
    data() {
        return {
            params: {
                pageNum: 1,
                pageSize: 10,
                startTime: '',
                endTime: '',
                customerId: ''
            },
            tabledata: [{
                numReceive: 0,
                numDelivery: 0
            }, {
                numReceive: 0,
                numDelivery: 0
            }, {
                numReceive: 0,
                numDelivery: 0
            }]
        }
    },
    // components: { centerRight2Chart1 }
    mounted() {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        this.params.customerId = this.$route.query.customerId
        console.log(this.$route.query)
        this.getData()
    },
    methods: {
        async getData() {
            const res = await api.getBigSkuCount(this.params)
            if (res.ResultCode == 0) {
                this.tabledata = res.Result.map(v => {
                    if (!v) {
                        // console.log(v, '---------------')
                        return {
                            numDelivery: 0,
                            numReceive: 0
                        }
                    } else {
                        return v
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#centerLeftBottom {
    //$box-height: 410px;
    //$box-width: 500px;
    //padding: 0 16px;
    // height: $box-height;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    .text_title{
        padding-top: 0.5rem
    }
    .ul_item {
        li {
            padding: 10px;

            span {
                flex: 1;
                text-align: center;
            }

            span:nth-child(1) {
                text-align: left;
            }
        }
    }

    .bg-color-black {
        // padding: 5px;
        height: 100%;
        // width: $box-width;
        border-radius: 10px;
        padding: 0 20px;
    }
    .title-box{
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .body-box {
        // border-radius: 10px;
        // overflow: hidden;
        //margin-top: 15px;
        height: 80%;
        .dv-cap-chart {
            width: 100%;
            height: 160px;
        }
    }
    .f14{
        font-size: 14px;
    }
}
</style>