<template>
  <div>
    <Echart :options="options" id="rightCenter1" :height="height + 'px'" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
      height: 180,
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        // console.log(newData.data, 'data洗涤次数分析--------------')
        // 固定样式数据
        let lineStyle = {
          normal: {
            width: 1,
            opacity: 0.5
          }
        };
        // There should not be negative values in rawData
        const rawData = [
          [100, 302, 301, 334, 390, 330, 320],
          [320, 132, 101, 134, 90, 230, 210],
          [220, 182, 191, 234, 290, 330, 310],
          [150, 212, 201, 154, 190, 330, 410],
          [820, 832, 901, 934, 1290, 1330, 1320]
        ];
        const totalData = [];
        for (let i = 0; i < rawData[0].length; ++i) {
          let sum = 0;
          for (let j = 0; j < rawData.length; ++j) {
            sum = rawData[j][i];
          }
          totalData.push(sum);
        }
        let grid = {
          left: '20%',
          right: '10%',
          top: '36%',
          bottom: '28%'
        };
        // console.error('-------newData',newData)
        const series = newData.seriesData.map((item, sid) => {
          return {
            name: item.name != null ? item.name : '-',
            type: 'bar',
            stack: 'total',
            barWidth: '60%',
            // label: {
            //   show: true,
            //   formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
            // },
            data: newData.data[sid]
          };
        });
        this.options = {
          color: [
            "#4d9f87",
            "#006ce6",
            "#6870d0",
            "#37a2da",
            "#00d2f6",
            "#ffdb5c",
            "#8ec9f4",

            // "#8378ea",
            // "#37a2da",
            // "#6871d0",
            // "#4d9f88",
            // "#ffdb5c",
            // "#006de6",
            // "#00d2f5",
            // "#e7bcf3",
            
          ],
          tooltip: {
            show: 'true',
            trigger: 'axis',
            // 点击展示提示框
            // triggerOn: 'click',
            textStyle: {
              fontSize: 12
            },
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            }
          },
          legend: {
            icon: 'circle',
            selectedMode: false,
            top: '5%',
            itemHeight: 7, //修改icon图形大小
            textStyle: {
              fontSize: 12
            },
          },
          grid,
          yAxis: {
            type: 'value',
            name: '',
            nameTextStyle: {
              fontSize: 12
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(161,164,165,0.5)"
              }
            },
            axisLine: {
              lineStyle: {
                color: "rgba(161,164,165,0.5)"
              }
            },
            axisLabel: {
              formatter: function (params) {
                // console.log(params, '-y轴+++++++++++++')
                // return params
                return params / 10;
              },
              textStyle: {
                fontSize: 12
              }
            }
          },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: "rgba(161,164,165,0.5)"
              }
            },
            axisLabel: {
              rotate: 35, // 文字旋转 45 度
              formatter: function (params) {
                // console.log(params, '-y轴+++++++++++++')
                return params
              },
              textStyle: {
                fontSize: 12
              },
            },
            // data: ['0-20', '41-60', '81-100', '121-140']
            data: ['0-20', '21-40', '41-60', '61-80', '81-100', '101-120', '121-140']
          },
          series
        }
        if(this.$screenWidth >= 1520){
            this.height = 230
            this.options.grid = {
              left: '15%',
              right: '10%',
              top: '20%',
              bottom: '28%'
            }
            this.options.legend.textStyle.fontSize = 14
            this.options.tooltip.textStyle.fontSize = 14
            this.options.xAxis.nameTextStyle.fontSize = 14
            this.options.xAxis.axisLabel.textStyle.fontSize = 14
            this.options.yAxis.nameTextStyle.fontSize = 14
            this.options.yAxis.axisLabel.textStyle.fontSize = 14
        }else if(this.$screenWidth >=1400 && this.$screenWidth < 1520){
          this.height = 200
          this.options.grid = {
              left: '15%',
              right: '10%',
              top: '20%',
              bottom: '35%'
            }
        }else{
          this.height = 180
        }


        // this.options = {
        //   radar: {
        //     indicator: newData.indicatorData,
        //     shape: "circle",
        //     splitNumber: 5,
        //     radius: ["0%", "65%"],
        //     name: {
        //       textStyle: {
        //         color: "rgb(238, 197, 102)"
        //       }
        //     },
        //     splitLine: {
        //       lineStyle: {
        //         color: [
        //           "rgba(238, 197, 102, 0.1)",
        //           "rgba(238, 197, 102, 0.2)",
        //           "rgba(238, 197, 102, 0.4)",
        //           "rgba(238, 197, 102, 0.6)",
        //           "rgba(238, 197, 102, 0.8)",
        //           "rgba(238, 197, 102, 1)"
        //         ].reverse()
        //       }
        //     },
        //     splitArea: {
        //       show: false
        //     },
        //     axisLine: {
        //       lineStyle: {
        //         color: "rgba(238, 197, 102, 0.5)"
        //       }
        //     }
        //   },
        //   series: [
        //     {
        //       name: "北京",
        //       type: "radar",
        //       lineStyle: lineStyle,
        //       data: newData.dataBJ,
        //       symbol: "none",
        //       itemStyle: {
        //         normal: {
        //           color: "#F9713C"
        //         }
        //       },
        //       areaStyle: {
        //         normal: {
        //           opacity: 0.1
        //         }
        //       }
        //     },
        //     {
        //       name: "上海",
        //       type: "radar",
        //       lineStyle: lineStyle,
        //       data: newData.dataSH,
        //       symbol: "none",
        //       itemStyle: {
        //         normal: {
        //           color: "#B3E4A1"
        //         }
        //       },
        //       areaStyle: {
        //         normal: {
        //           opacity: 0.05
        //         }
        //       }
        //     },
        //     {
        //       name: "广州",
        //       type: "radar",
        //       lineStyle: lineStyle,
        //       data: newData.dataGZ,
        //       symbol: "none",
        //       itemStyle: {
        //         normal: {
        //           color: "rgb(238, 197, 102)"
        //         }
        //       },
        //       areaStyle: {
        //         normal: {
        //           opacity: 0.05
        //         }
        //       }
        //     } //end
        //   ]
        // }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>