<template>
    <div id="rightBottom">
        <div class="bg-color-black">
            <div class="d-flex title11">
                <!-- <span>
                    <icon name="align-left" class="text-icon"></icon>
                </span> -->
                <span class="fs-xl text_title mx-2 commonTitle">织物使用量排名</span>
            </div>
            <div class="d-flex flex-column body-box padding-xs title22">
                <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
                <!-- <centerRight2Chart1 /> -->
                <!-- <div class="padding-xs d-flex jc-end">平均洗涤寿命</div> -->
                <!-- <ul class="ul_item">
                    <li v-for="(item, idx) in result" :key="idx" class="d-flex ">
                        <span class="span_t">{{ item.spuName }}</span>
                        <div class="item_li">
                            <div class="default_num" :style="{ width: item.num + 'px' }">{{
                                item.num }}</div>
                        </div>
                    </li>
                </ul> -->
                <dv-scroll-ranking-board :config="config" style="width:100%;height:100%;" />
            </div>
        </div>
    </div>
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'
import * as api from '@/api/index'
export default {
    data() {
        return {
            config: {
                waitTime: 5000,

                data: [
                    {
                        name: '国内',
                        value: 167
                    },
                    {
                        name: '海外',
                        value: 67
                    },
                    {
                        name: '汇总',
                        value: 234
                    },
                    {
                        name: '郑州',
                        value: 55
                    },
                    {
                        name: '西峡',
                        value: 98
                    },
                    {
                        name: '汇总',
                        value: 234
                    },
                    {
                        name: '郑州',
                        value: 55
                    },
                    {
                        name: '西峡',
                        value: 98
                    }
                ]
            },
            colors: ['red', '#e55132', '#dd752e', '#e7a649', '#96d70f'],
            params: {
                customerId: ''
            },
            result: []
        }
    },
    // components: { centerRight2Chart1 }
    mounted() {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        this.params.customerId = this.$route.query.customerId

        this.getData()
    },
    methods: {
        async getData() {
            const res = await api.getBigSkuWashSpu(this.params)
            // console.log(res, '布草洗涤次数排名-----------')
            if (res.ResultCode == 0) {
                this.config.data = res.Result.sort(function (a, b) {
                    return b.num - a.num; // 从大到小排序
                }).map(v => {
                    return {
                        name: v.spuName,
                        value: v.num,
                    }
                });
                this.config = { ...this.config }
                // console.log(this.config.data, 'this.result---------')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#rightBottom {
    ::v-deep  .ranking-column {
        border: none;
    }



   // $box-height: 410px;
    //$box-width: 500px;
    padding: 0 16px;
    height: 100%;
    width: 100%;
    border-radius: 5px;

    .ul_item {
        //height: 200px;
        overflow-y: auto;
        margin-top: 15px;
        padding-left: 30px;

        li {
            padding: 10px;
            align-items: center;

            .span_t {
                width: 100px;
            }

            .item_li {
                width: 280px;
                margin-right: 5px;
            }

            .default_num {
                max-width: 260px;
                height: 15px;
                background-color: #ea401e;
                text-align: center;
            }
        }
    }

    .bg-color-black {
        // padding: 5px;
        //height: 280px;
        // width: $box-width;
        border-radius: 10px;
        height: 100%;
        .title11{
            height: 18%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .title22{
            height: 82%;
        }
    }

    .body-box {
        border-radius: 10px;
        overflow: hidden;

        .dv-cap-chart {
            width: 100%;
            //height: 160px;
        }
    }
    .title11{
        height: 15%;
    }
    .title22{
        height: 85%;
    }
}
</style>